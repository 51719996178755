import {IsNotEmpty, ValidateIf} from 'class-validator'
import {FormClass, FormField, InputType, SelectOption} from "@stadtlandnetz/forms";
import type {SchoolClassID, SchoolID} from "../entities";
import type {SystemID} from "../../system";
import type {SchoolYearID} from "../../calendar";
import type {SchoolSearchResult} from "./SchoolSearchResult.dto";
import { ApiProperty } from '@nestjs/swagger';

@FormClass({interfereLabels: true, defaultGroupName: 'forms.schoolInformation'})
export class SchoolWish {
    @ApiProperty({example: 362})
    @FormField({
        type: InputType.SELECT,
        title: 'schoolType',
        oneliner: true
    })
    @IsNotEmpty()
    type: SystemID

    @ApiProperty({example: 322})
    @FormField({
        type: InputType.DROPDOWNSEARCH,
        title: 'schoolName',
        oneliner: true
    })
    @IsNotEmpty()
    school: SchoolID

    @ApiProperty({example: 381})
    @FormField({
        type: InputType.SELECT,
        title: 'schoolForm',
        oneliner: true
    })
    @ValidateIf(o => Array.isArray(o.formOptions) && o.formOptions.length > 0)
    @IsNotEmpty()
    form?: SystemID

    @FormField({
        type: InputType.SELECT,
        hidden: true
    })
    formOptions?: SelectOption[]

    @ApiProperty({example: null})
    @FormField({
        type: InputType.SELECT,
        title: 'schoolProfile',
        oneliner: true
    })
    @ValidateIf(o => Array.isArray(o.profileOptions) && o.profileOptions.length > 0)
    @IsNotEmpty()
    profile?: SystemID | null

    @FormField({
        type: InputType.SELECT,
        hidden: true
    })
    profileOptions?: SelectOption[]

    @ApiProperty({example: 3})
    @FormField({
        type: InputType.SELECT,
        title: 'schoolYear',
        class: 'onelinerMobile'
    })
    @IsNotEmpty()
    year: SchoolYearID

    @ApiProperty({example: 883})
    @FormField({
        type: InputType.SELECT,
        title: 'schoolClass',
        class: 'onelinerMobile'
    })
    @IsNotEmpty()
    class: SchoolClassID

    constructor(init?: Partial<SchoolWish>) {
        Object.assign(this, init)
    }
}

export type SchoolWishWithSearchResult = {
    type: SystemID
    form?: SystemID
    profile?: SystemID
    school: SchoolSearchResult
    year: SchoolYearID
    class: SchoolClassID
}

export type SchoolWishOptions = {
    daysToWaitForSchoolYear?: number
    daysToWaitUntilStart?: number
}